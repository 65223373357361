import { defineComponent, reactive, computed } from "vue";
import TranformUtil from "@/commons/TransformUtil";
export default defineComponent({
    name: "CurrencyInput",
    props: ["placeholder", "modelValue"],
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            placeholder: props.placeholder,
            isInputActive: false
        });
        var value = computed({
            get: function () {
                if (state.isInputActive) {
                    return props.modelValue;
                }
                else {
                    return props.modelValue && Number(props.modelValue) != NaN && Number(props.modelValue) >= 0 ? "\u00A5" + TranformUtil.formatNumber(props.modelValue) : props.modelValue;
                }
            },
            set: function (val) {
                emit("update:modelValue", val);
            }
        });
        return {
            state: state,
            value: value
        };
    }
});
