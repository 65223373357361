<template>
    <span class="popover__help_icon" ref="iconRef">
      <i class="fas fa-question"></i>
    </span>
    <div class="popover" id="content" ref="contentRef">
      <header class="popover__header" v-if="header">
        {{ header }}
      </header>
      <div class="popover__body">
        <slot></slot>
      </div>
    </div>
</template>

<script lang="ts" src="./Popover.ts" scoped>
</script>

<style lang="scss" src="./Popover.scss">
</style>