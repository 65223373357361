import tippy from "tippy.js";
import { defineComponent, onMounted, ref, } from "vue";
export default defineComponent({
    name: "Tooltip",
    props: ['header'],
    setup: function () {
        var iconRef = ref();
        var contentRef = ref();
        onMounted(function () {
            tippy(iconRef === null || iconRef === void 0 ? void 0 : iconRef.value, {
                content: contentRef === null || contentRef === void 0 ? void 0 : contentRef.value,
                trigger: 'click',
                theme: 'light',
                placement: 'right-start'
            });
        });
        return {
            iconRef: iconRef,
            contentRef: contentRef,
        };
    }
});
