<template>
    <div class="datepicker" :class="{inactive: value == ''}" v-click-outside="hideCalendar" @click="showCalendar">
        <input type="text" :placeholder="props.placeholder" v-model="value" :disabled="props.disable" readonly>
        <a class="clear" v-if="value != '' && !props.disable" @click="clearData"><i class="far fa-times-circle"></i></a>
        <div class="calendar" v-if="state.showCalendar">
            <div class="header-calendar">
                <a class="previous-month" @click="previousMonth"><i class="fas fa-chevron-left"></i></a>
                <span>{{state.month + " " + state.year}}</span>
                <a class="next-month" @click="nextMonth"><i class="fas fa-chevron-right"></i></a>
            </div>
            <div class="list-date">
                <div class="dates-header">
                    <div class="date-header" v-for="day of state.weekDay" :key="day">
                        {{day}}
                    </div>
                </div>
                <div class="dates">
                    <div class="date" v-for="day of state.listDates" :key="day.value" :class="{
                        now: day.value == state.now, 
                        'not-in-month': !day.active, 
                        active: day.value == state.date,
                        inactive: (props.minDate && day.time < state.minDate) || (props.maxDate && day.time > state.maxDate)
                        }" @click="selectDate(day.value)">
                        {{day.date}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/components/datepicker/DatePicker.ts">
</script>

<style lang="scss" src="@/presentation/components/datepicker/DatePicker.scss" scoped>
</style>