import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import NavBar from "@/presentation/components/navigation/Navigation.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import LanguageUtil from "@/commons/LanguageUtil";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import DIContainer from "@/core/DIContainer";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import ProjectInteractor from "@/domain/usecases/ProjectInteractor";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import Project from "@/domain/entity/Project";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import routePath from "@/commons/RoutePath";
import { useStore } from "vuex";
import DatePicker from "@/presentation/components/datepicker/DatePicker.vue";
import ProjectTitle from "@/presentation/components/project-title/ProjectTitle.vue";
import CurrencyInput from "@/presentation/components/currency-input/CurrencyInput.vue";
import * as agh from "agh.sprintf";
import Input from "@/presentation/components/input/Input.vue";
import PhasePopover from "@/presentation/components/popover/phase-popover/PhasePopover.vue";
import { cloneDeep, orderBy } from 'lodash';
export default defineComponent({
    name: "CreateProject",
    components: {
        Breadcrumb: Breadcrumb,
        NavBar: NavBar,
        Textarea: Textarea,
        ErrorMessage: ErrorMessage,
        DatePicker: DatePicker,
        ProjectTitle: ProjectTitle,
        CurrencyInput: CurrencyInput,
        Input: Input,
        PhasePopover: PhasePopover
    },
    setup: function () {
        var router = useRouter();
        var store = useStore();
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        var projectInteractor = DIContainer.instance.get(ProjectInteractor);
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var _a = useForm({
            initialValues: {
                title: "",
                description: "",
                category_id: "",
                appeal_point_from_company: "",
                expectation_for_student: "",
                regional_hub_comment: "",
                regional_hub_contact: "",
                qa: "",
                note: "",
                file_base64: "",
                progress_status: "",
                data_types: [],
                available_datetimes: [],
            }
        }), setValues = _a.setValues, setFieldValue = _a.setFieldValue, values = _a.values, isSubmitting = _a.isSubmitting, errors = _a.errors, setErrors = _a.setErrors, handleSubmit = _a.handleSubmit;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: "/organization/dashboard",
                    name: "ダッシュボード"
                },
                {
                    route: "/organization/dashboard",
                    name: "プロジェクト管理"
                },
                {
                    route: "",
                    name: "プロジェクト登録"
                }
            ],
            projectCategories: [],
            prefectures: [],
            cities: [],
            dataTypes: [],
            availableDatetimes: [],
            loadingSaveDraft: false,
            loadingCreateProject: false,
            errorValidateMessage: [],
            serverError: null,
        });
        var numberRegex = /^\d*$/;
        // validate
        var title = useField("title", yup
            .string()
            .required(state.aLang.validation_required)
            .min(3, agh.sprintf(state.aLang.validation_min_length, 3))
            .max(50, agh.sprintf(state.aLang.validation_max_length, 50))).value;
        var description = useField("description", yup
            .string()
            .required(state.aLang.validation_required)
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))).value;
        var category_id = useField("category_id", yup
            .string()
            .required(state.aLang.validation_required)).value;
        var available_datetimes = useField("available_datetimes", yup
            .array()
            .required(state.aLang.validation_required)).value;
        var qa = useField("qa", yup
            .string()
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))).value;
        var note = useField("note", yup
            .string()
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))).value;
        var file_base64 = useField("file_base64", yup
            .string()).value;
        function convertDatetime(date) {
            if (!date) {
                return "";
            }
            var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }
            return [year, month, day].join('-');
        }
        function checkDate(value, field, errorMessage1, errorMessage2) {
            if (!field.includes("now") && !values[field]) {
                return true;
            }
            var date = field.includes("now") ? new Date(convertDatetime(new Date())) : new Date(values[field]);
            if (field.includes("now") && values[field.replace("now", "end_at")] && new Date(value).getTime() >= new Date(values[field.replace("now", "end_at")]).getTime()) {
                var error = {};
                error[field.replace("now", "end_at")] = errorMessage2;
                setErrors(error);
            }
            else if (field.includes("now")) {
                var error = {};
                error[field.replace("now", "end_at")] = undefined;
                setErrors(error);
            }
            if (!field.includes("now") && new Date(value).getTime() <= date.getTime()) {
                return errorMessage1;
            }
            return true;
        }
        function onChangeDataTypes(event, dataType) {
            var id = parseInt(dataType.id);
            values.data_types = values.data_types ? values.data_types.filter(function (item) { return item.id !== id; }) : [];
            if (event.currentTarget.checked) {
                values.data_types.push(dataType);
            }
        }
        function onChangeAvailableDatetimes(event, availableDatetime) {
            var id = parseInt(availableDatetime.id);
            var newDatetimes = values.available_datetimes ? values.available_datetimes.filter(function (item) { return item.id !== id; }) : [];
            if (event.currentTarget.checked) {
                newDatetimes.push(availableDatetime);
            }
            values.available_datetimes = orderBy(newDatetimes, ['id'], ['asc']);
        }
        var saveDraft = handleSubmit(function () {
            state.loadingSaveDraft = true;
            var data = new Project;
            delete data.project_id;
            data = Object.assign(data, values);
            data.progress_status = "TEMP_SAVED";
            organizationProjectInteractor.creatProject(data).then(function (result) {
                state.loadingSaveDraft = false;
                if (!result.data) {
                    return;
                }
                store.commit("setProjectTempSaved", result.data.id);
                router.push(routePath.PROJECT_DETAIL + result.data.id);
            }).catch(function (error) {
                state.loadingSaveDraft = false;
                state.errorValidateMessage = {};
                if (error && error.validation_errors) {
                    Object.assign(state.errorValidateMessage, error.validation_errors);
                }
                else if (error && error.message) {
                    state.serverError = error.message;
                }
            });
        });
        var createProject = handleSubmit(function () {
            state.loadingCreateProject = true;
            var data = new Project;
            data = Object.assign(data, values);
            data.progress_status = "UNAPPROVED";
            store.commit("setOrganizationProjectData", data);
            state.loadingCreateProject = false;
            return router.push(routePath.ORGANIZATION_CONFIRM_PROJECT);
        });
        function goBack() {
            return router.push(routePath.ORGANIZATION_DASHBOARD);
        }
        function checkErrorValidate(field, error) {
            if (!error[field]) {
                return false;
            }
            if (state.errorValidateMessage[field]) {
                state.errorValidateMessage[field] = [];
            }
            return true;
        }
        function onChangeImage(e) {
            setValues({
                file_base64: e
            });
        }
        function initData() {
            var organizationProjectData = cloneDeep(store.state.organizationProjectData);
            if (organizationProjectData.title) {
                if (organizationProjectData.file_base64) {
                    var dataTitle = JSON.parse(JSON.stringify(organizationProjectData));
                    dataTitle.image_file_name = dataTitle.file_base64;
                    store.commit("setProjectInfo", dataTitle);
                }
                setValues(organizationProjectData);
            }
            Promise.all([
                projectInteractor.getProjectCategories().then(function (result) {
                    state.projectCategories = result.data;
                }),
                masterInteractor.getDataTypes().then(function (result) {
                    state.dataTypes = result.data;
                }),
                masterInteractor.getAvailableDatetimes().then(function (result) {
                    state.availableDatetimes = result.data;
                }),
            ]);
        }
        onMounted(function () {
            initData();
        });
        router.beforeEach(function (to, from, next) {
            if ((from.name == "OrganizationDashboardCreateProject" && to.path != routePath.ORGANIZATION_CONFIRM_PROJECT) ||
                (from.name == "OrganizationDashboardConfirmProject" && to.name != "OrganizationDashboardCreateProject" && to.name != "OrganizationProjectEdit") ||
                (from.name == "OrganizationProjectEdit" && to.path != routePath.ORGANIZATION_CONFIRM_PROJECT)) {
                store.commit("setOrganizationProjectData", {});
                store.commit('setProjectInfo', {});
            }
            next();
        });
        return {
            state: state,
            values: values,
            errors: errors,
            isSubmitting: isSubmitting,
            saveDraft: saveDraft,
            createProject: createProject,
            goBack: goBack,
            checkErrorValidate: checkErrorValidate,
            onChangeImage: onChangeImage,
            onChangeDataTypes: onChangeDataTypes,
            onChangeAvailableDatetimes: onChangeAvailableDatetimes
        };
    }
});
