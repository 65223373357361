<template>
    <Popover>
        <strong>
            構想段階：AIプロジェクトの準備とは
        </strong>
        <p>
            該当ステージ：AIを活用するテーマが決まっていない。<br/>
            協働と成果：想定しているテーマ候補がAIで解決可能か？どんなテーマがAIに向いているか？などを議論することで
            AI活用の理解を深め、具体的なテーマ選定を目指します。
        </p>
        <strong>
            設計段階：AIプロジェクトの設計とは
        </strong>
        <p>
            該当ステージ：テーマは決まっているが、プロジェクトの進め方は具体化されていない。<br/>
            協働と成果：課題解決のためにAIをどのように活用していくのか？コスト試算・期待される効果などの
            見積もりや必要なデータや環境等の実施条件等を検討し、プロジェクトの具体化を目指します。
        </p>
        <strong>
            検証段階：AIモデル構築・精度検証とは
        </strong>
        <p>
            該当ステージ：プロジェクトは設計済みだが、想定しているAIが実現可能かわからない。<br/>
            協働と成果：実際にAIモデルを構築し精度や実行速度を検証することで、
            課題解決に対する期待効果の試算を目指します。
        </p>
    </Popover>
</template>

<script lang="ts" src="./PhasePopover.ts">
</script>
