import { defineComponent, reactive, computed, watchEffect } from "vue";
import TransformUtil from "@/commons/TransformUtil";
export default defineComponent({
    name: "DatePicker",
    props: ["placeholder", "modelValue", "maxDate", "minDate", "disable"],
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            listDates: [],
            month: null,
            year: null,
            weekDay: [
                "M",
                "T",
                "W",
                "T",
                "F",
                "S",
                "S"
            ],
            now: "",
            showCalendar: false,
            date: "",
            maxDate: null,
            minDate: null
        });
        function getDaysInMonth(month, year) {
            var date = new Date(year, month, 1);
            var days = [];
            if (date.getDay() != 1) {
                var cache = new Date(year, month, 1);
                cache.setDate(cache.getDate() - 1);
                while (cache.getDay() != 0) {
                    days.push({
                        date: cache.getDate(),
                        active: false,
                        value: TransformUtil.convertDatetime(cache, "-"),
                        time: cache.getTime()
                    });
                    cache.setDate(cache.getDate() - 1);
                }
            }
            ;
            while (date.getMonth() === month) {
                days.push({
                    date: date.getDate(),
                    active: true,
                    value: TransformUtil.convertDatetime(date, "-"),
                    time: date.getTime()
                });
                date.setDate(date.getDate() + 1);
            }
            ;
            if (date.getDay() != 1) {
                while (date.getDay() != 1) {
                    days.push({
                        date: date.getDate(),
                        active: false,
                        value: TransformUtil.convertDatetime(date, "-"),
                        time: date.getTime()
                    });
                    date.setDate(date.getDate() + 1);
                }
            }
            return days;
        }
        function previousMonth() {
            if (state.month != 1) {
                state.month--;
            }
            else {
                state.month = 12;
                state.year--;
            }
            state.listDates = getDaysInMonth(state.month - 1, state.year);
        }
        function nextMonth() {
            if (state.month != 12) {
                state.month++;
            }
            else {
                state.month = 1;
                state.year++;
            }
            state.listDates = getDaysInMonth(state.month - 1, state.year);
        }
        function selectDate(value) {
            var date = new Date(value).getTime();
            if ((props.maxDate && date > state.maxDate) || (props.minDate && date < state.minDate)) {
                return;
            }
            emit("update:modelValue", value);
            emit("change", value);
            state.showCalendar = false;
        }
        function showCalendar() {
            state.showCalendar = props.disable ? false : true;
        }
        function hideCalendar() {
            state.showCalendar = false;
        }
        function clearData() {
            emit("change", "");
            emit("update:modelValue", "");
        }
        var value = computed({
            get: function () {
                var now = new Date();
                var month = props.modelValue ? new Date(props.modelValue).getMonth() : now.getMonth();
                var year = props.modelValue ? new Date(props.modelValue).getFullYear() : now.getFullYear();
                state.now = TransformUtil.convertDatetime(now, "-");
                state.month = month + 1;
                state.year = year;
                state.listDates = getDaysInMonth(month, year);
                state.date = TransformUtil.convertDatetime(props.modelValue, "-");
                return TransformUtil.convertDatetime(props.modelValue);
            },
            set: function (val) {
                emit("update:modelValue", val);
            }
        });
        watchEffect(function () {
            state.maxDate = props.maxDate ? new Date(props.maxDate).getTime() : null;
            state.minDate = props.minDate ? new Date(props.minDate).getTime() : null;
        });
        return {
            state: state,
            props: props,
            value: value,
            previousMonth: previousMonth,
            nextMonth: nextMonth,
            hideCalendar: hideCalendar,
            showCalendar: showCalendar,
            selectDate: selectDate,
            clearData: clearData
        };
    }
});
