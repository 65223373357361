<template>
    <div class="currency_input">
        <input type="text" v-model="value" :placeholder="state.placeholder" @blur="state.isInputActive = false" @focus="state.isInputActive = true">
    </div>
</template>

<script lang="ts" src="@/presentation/components/currency-input/CurrencyInput.ts">
</script>

<style lang="scss" src="@/presentation/components/currency-input/CurrencyInput.scss" scoped>
</style>